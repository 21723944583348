export default class FCustomerGroup {
  constructor(
      id,
      kode1,
      kode2,
      description,
      statusActive,
      fdivisionBean,
      lockDiscNota,
      ftPriceAlthBean,
      autoOngkir,
      custOnline,
      sourceId,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.statusActive = statusActive;      // Boolean
    this.fdivisionBean = fdivisionBean;
    this.lockDiscNota = lockDiscNota;      // Boolean
    this.ftPriceAlthBean = ftPriceAlthBean;
    this.autoOngkir = autoOngkir;          // Boolean
    this.custOnline = custOnline;          // Boolean
    this.sourceId = sourceId;
    this.created = created;                // Expected as Date
    this.modified = modified;              // Expected as Date
    this.modifiedBy = modifiedBy;
  }
}
